import { studio } from '@kaliber/sanity-preview'
import sanityClient from '@sanity/client'
import { useReportError } from '/machinery/ReportError'
import { useClientConfig } from '/machinery/ClientConfig'
import { useTranslate } from '/machinery/I18n'

export function Preview({ documentPath }) {
  const previewState = useRedirectToPreview({ documentPath })
  const { __ } = useTranslate()

  return (
    previewState === 'loading' ? <>{__`preview-loading`}</> :
    previewState === 'invalid' ? <>{__`preview-invalid`}</> :
    previewState === 'unauthorized'   ? <>{__`preview-unauthorized`}</> :
    previewState === 'error'   ? <>{__`preview-error`}</> :
    throwError(`Unexpected preview state '${previewState}'`)
  )
}

function useRedirectToPreview({ documentPath }) {
  const config = useClientConfig()
  const [state, setState] = React.useState(documentPath ? 'loading' : 'invalid')
  const reportError = useReportError()

  React.useEffect(
    () => {
      if (!documentPath) return
      studio.getPreviewSecret({ sanityClient: sanityClient(config.sanity.clients.studio) })
        .then(previewSecret => redirect(`${documentPath}?preview=${previewSecret}`))
        .catch(e => {
          if (e.statusCode === 403) return setState('unauthorized')

          setState('error')
          reportError(e)
        })
    },
    [config, documentPath]
  )

  return state
}

function redirect(url) { document.location.href = url }

/** @returns {never} */
function throwError(message) { throw new Error(message) }
